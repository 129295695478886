<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <form
                  class="form"
                  @submit.stop.prevent="formOnsubmit()"
                  v-if="dataLoaded"
                >
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h3
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h4 font-size-h1-lg
                      "
                    >
                      Edit Kehadiran
                    </h3>
                    <div class="row align-items-center">
                      <div class="col-md-4 col-sm-12">
                        <!-- <b-form-group id="input-group-photo">
                          <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                            <div
                              class="image-input image-input-outline"
                              id="kt_profile_avatar"
                            >
                              <div class="image-input-wrapper">
                                <img :src="photo" alt="" />
                              </div>
                              <label
                                class="
                                  btn
                                  btn-xs
                                  btn-icon
                                  btn-circle
                                  btn-white
                                  btn-hover-text-primary
                                  btn-shadow
                                "
                                data-action="change"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Change avatar"
                              >
                                <i class="fa fa-pen icon-sm text-muted"></i>
                                <input
                                  type="file"
                                  name="profile_avatar"
                                  accept=".png, .jpg, .jpeg"
                                  @change="onFileChange($event)"
                                />
                                <input
                                  type="hidden"
                                  name="profile_avatar_remove"
                                />
                              </label>
                              <span
                                class="
                                  btn
                                  btn-xs
                                  btn-icon
                                  btn-circle
                                  btn-white
                                  btn-hover-text-primary
                                  btn-shadow
                                "
                                data-action="cancel"
                                data-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i
                                  class="ki ki-bold-close icon-xs text-muted"
                                ></i>
                              </span>
                              <span
                                class="
                                  btn
                                  btn-xs
                                  btn-icon
                                  btn-circle
                                  btn-white
                                  btn-hover-text-primary
                                  btn-shadow
                                "
                                data-action="remove"
                                data-toggle="tooltip"
                                title="Remove avatar"
                                @click="current_photo = null"
                              >
                                <i
                                  class="ki ki-bold-close icon-xs text-muted"
                                ></i>
                              </span>
                            </div>
                          </div>
                        </b-form-group> -->
                        <vue-web-cam
                          ref="webcam"
                          :device-id="deviceId"
                          width="100%"
                          height="100%"
                          @started="onStarted"
                          @stopped="onStopped"
                          @error="onError"
                          @cameras="onCameras"
                          @camera-change="onCameraChange"
                          v-if="capture_status == false"
                        />
                        <img :src="baseImg" height="100%" width="100%" v-if="capture_status" />
                        <b-button
                         variant="info"
                         type="button"
                         class="w-100 mt-2"
                         v-if="capture_status"
                         @click="cameraActivated"
                        >
                            Aktifkan Kamera 
                        </b-button>
                        <b-button
                         type="button"
                         variant="primary"
                         class="w-100 mt-2"
                         @click="onCapture"
                         v-if="capture_status == false"
                        >
                        <i class="fas fa-camera"></i>
                        </b-button>
                      </div>
                      <div class="col-md-8 pl-0 col-sm-12">
                        <b-form-group
                          id="input-group-name"
                          label="Nama:"
                          label-for="input-name"
                          class="mt-3"
                        >
                          <b-form-input
                            id="input-name"
                            v-model="form.created_by_name"
                            placeholder="Nama"
                            disabled
                          ></b-form-input>
                          <small class="text-danger">{{ error.name }}</small>
                        </b-form-group>
                        <b-form-group
                          id="input-group-date"
                          label="Waktu Absen:"
                          label-for="input-date"
                          class="mt-3"
                        >
                          <b-form-input
                            id="input-date"
                            v-model="form.date"
                            placeholder="Waktu Absen"
                            disabled
                          ></b-form-input>
                          <small class="text-danger">{{ error.date }}</small>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="$router.push('/presence')"
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { WebCam } from "vue-web-cam";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  components: {
    "vue-web-cam": WebCam,
  },
  data() {
    return {
      // camera
      camera: null,
      deviceId: null,
      devices: [],
      img: null,
      baseImg: null,
      content: "list",
      //   form
      form: {
        name: "",
        description: "",
      },
      error: {
        name: "",
        description: "",
      },
      dataLoaded: false,
      // other
      current_photo: null,
      default_photo: "/img/blank.jpg",
      capture_status: false,
    };
  },
  methods: {
    async get() {
      this.form = await module.get(
        "api/student-presences/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/presence");
      } else {
        this.form["_method"] = "put";
        this.dataLoaded = true;
        this.default_photo = this.form.photo;
        this.current_photo = this.form.photo;
        this.capture_status = true
        this.baseImg = this.form.photo
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.image = "";
    },

    async formOnsubmit() {
      this.form.student_name = getUser().name
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(
        formData,
        "api/student-presences/" + this.$route.params.id
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/presence");
      }
    },
    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },

    // camera method
    onCapture() {
      this.baseImg = this.$refs.webcam.capture();
      this.img = this.dataURItoBlob(this.baseImg);
      this.form.photo = this.dataURItoBlob(this.baseImg);
      this.capture_status = true;
      //("form", this.form);
    },
    onStarted(stream) {
      //("On Started Event", stream);
    },
    onStopped(stream) {
      //("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      //("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      //("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      //("On Camera Change Event", deviceId);
    },
    cameraActivated(){
    setTimeout(() => {
        this.onStart();
      }, 4000);
      this.capture_status = false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kehadiran", route: "/presence" },
      { title: "Edit Kehadiran" },
    ]);

    this.get();
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
};
</script>